import React from "react";
import { Helmet } from "react-helmet";

import "./TutorialStylesheet.css";

function TutorialPage() {
  return (
    <>
      <body className="tutorial-body">
        <div className="tutorial-container">
          <div className="tutorial-video">
            <video controls>
              <source src='/videos/tutorial.mp4' />
            </video>
          </div>
          <div className="tutorial-text">
            <h2><span>TipeSites</span> Tutorial</h2>
            <p>To better understand the platform, watch the
               provided tutorial to see some of the features
               and capabilities that the TipeSites platform offers.</p>
          </div>
        </div>
      </body>
    </>
  );
}

export default TutorialPage;
