import React, { useState } from "react";
import { Link } from "react-router-dom";
import CloseIcon from "@heroicons/react/24/solid/XMarkIcon";
import BarsIcon from "@heroicons/react/24/solid/Bars3Icon";
import logo from "../Logo/Logo.png";
import styles from "./NavBar.module.css";

const navBarItems = [
  { route: "/school-register", label: "Register" },
  { route: "https://learning.tipesites.site/login/index.php", label: "Login" }
];

const NavBar = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <header className={styles.header}>
      <Link to="/" className={styles.logo}>
        <img src={logo} style={{ width: 50 }} alt="Logo" />
      </Link>
      <nav className={`${styles.navbar} ${isOpen ? styles.responsiveNav : ""}`}>
        {navBarItems.map((navItem, i) => (
          navItem.route.startsWith("http") ? (
            <a
              key={i}
              href={navItem.route}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setOpen(false)}
              style={{ "--i": i + 1 }}
            >
              {navItem.label}
            </a>
          ) : (
            <Link
              key={i}
              onClick={() => setOpen(false)}
              to={navItem.route}
              style={{ "--i": i + 1 }}
            >
              {navItem.label}
            </Link>
          )
        ))}

        <button
          className={`${styles.navBtn} ${styles.navCloseBtn}`}
          onClick={() => setOpen((o) => !o)}
        >
          <CloseIcon width={30} height={30} fill="white" stroke="white" />
        </button>
      </nav>
      <button className={styles.navBtn} onClick={() => setOpen((o) => !o)}>
        <BarsIcon width={30} height={30} fill="white" stroke="white" />
      </button>
    </header>
  );
};

export default NavBar;
