import React, { useEffect, useRef, useState } from "react";
import "./PricesTablePage.css";

function PricesTablePage() {
    return (
        <div className="pricestable-body">
            <h2> <span>P</span><span>a</span><span>c</span><span>k</span><span>a</span><span>g</span><span>e</span><span>s</span></h2>
            <table>
                <tr className="row">
                    <th className="header FeatureHeader">Features</th>
                    <th className="header StandardHeader">Standard</th>
                    <th className="header PremiumHeader">Premium</th>
                </tr>
                <tr className="space">
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr className="row">
                    <td className="Feature top FeatureTop">Price per user monthly</td>
                    <td className="Standard top StandardTop">R20.00</td>
                    <td className="Premium top PremiumTop">R35.00</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Available roles</td>
                    <td className="Standard">Supervisor, Teacher, Student</td>
                    <td className="Premium">Supervisor, Teacher, Student, Parent</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Course search</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Course request</td>
                    <td className="Standard">No</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Course search, creation & management</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Activity creation & management</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Lesson content creation & management (includes videos and files)</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Group creation & management</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Assignment creation & management</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Quiz automatic marking</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Submission portal</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Advanced text editor</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">User facilitation & management</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Direct messaging</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Dashbaord & calendar access</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature">Report generation & management</td>
                    <td className="Standard">Yes</td>
                    <td className="Premium">Yes</td>
                </tr>
                <tr className="row">
                    <td className="Feature bottom FeatureBottom">Report export</td>
                    <td className="Standard bottom StandardBottom">Yes</td>
                    <td className="Premium bottom PremiumBottom">Yes</td>
                </tr>
            </table>
        </div>
    );
}

export default PricesTablePage;