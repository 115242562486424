import React, { useEffect, useRef, useState } from "react";
import "./AboutStylesheet.css";
import complexGif from './images/business.gif';

function AboutPage() {
  const navRef = useRef();

  useEffect(() => {
    const cubes = document.querySelectorAll(".cube");

    cubes.forEach((cube, index) => {
      cube.style.animationDuration = `${3 + index}s`; // Adjust the speed of rotation
    });
  }, []);

  return (
    <>
      <div className="about-body">
        <div className="about-container">
          <h2>
            What is <span>TipeSites?</span>
          </h2>
          <p>
            TipeSites is a sophisticated online educational platform designed
            to usher educational institutions and learners into an era of
            digital convenience and automation. Our platform is equipped with
            an extensive suite of tools aimed at enhancing the educational
            experience:
            <br />
            <br />
            <ul>
              <li> - Creation of subjects and courses</li>
              <li> - Management of user performance</li>
              <li> - Automated test creation and grading</li>
              <li> - Assignment submission portals</li>
              <li> - Advanced custom text editor</li>
              <li> - Interactive group chats and notifications</li>
              <li> - Resource uploads for books and reviews</li>
              <li> - Efficient course search functionality</li>
            </ul>
            <br />
            To ensure each participant's success, TipeSites delineates specific
            roles, each equipped with comprehensive tools to support and guide
            their educational journey:
            <br />
            <br />
            <ul>
              <li>
                 - Supervisor Role: Oversees school, teacher, course, assignments,
                grades, and student management.
              </li>
              <li>
                 - Teacher Role: Manages courses, assignments, and student
                engagement.
              </li>
              <li> - Student Role: Accesses and manages assignments and grades.</li>
              <li> - Parent Role: Monitors child’s assignments and grades.</li>
            </ul>
            <br />
            TipeSites advocates for a seamless integration of online and
            traditional on-site education, offering the essential tools to
            streamline educational processes. Our platform simplifies
            educational management, embodying our commitment to making education
            'Just that easy!'.
          </p>
        </div>

        <div className='about-image'>
          <img src={complexGif} alt='Tech Image' />
        </div>
      </div>
    </>
  );
}

export default AboutPage;
