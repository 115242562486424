import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import styles from "./MainPage.module.css";

import AboutPage from "./AboutPage";
import PricesTablePage from "./PricesTablePage";
import TutorialPage from "./TutorialPage";
import ContactPage from "./ContactPage";

import AptitudeTestPage from "./AptitudeTestPage";

function MainPage() {
  const typedInstanceRef = useRef(null);

  useEffect(() => {
    typedInstanceRef.current = new Typed(`.${styles.multipleText}`, {
      strings: ["easy!", "convenient!", "simple!"],
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 1000,
      loop: true,
    });

    return () => {
      typedInstanceRef.current.destroy();
    };
  }, []);

  return (
    <>
      <section className={styles.home}>
        <div className={styles.homeContent}>
          <div className={styles.homeText}>
            <h3>Welcome to</h3>
            <h1>
              <span>Tipe</span>Sites
            </h1>
            <h2>
              It's just that <span className={styles.multipleText}></span>
            </h2>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <a href="/school-register" className={styles.btn}>
            JOIN NOW
          </a>
        </div>
      </section>

      <AboutPage />
      <TutorialPage />
      <PricesTablePage />
      <ContactPage />
      {/* <AptitudeTestPage /> */}

      <footer>
        <div>
          Email: <a href="mailto:hq@tipesites.site">hq@tipesites.site</a>
        </div>
        <div>Location: South Africa, Johannesburg</div>
        <div>
          Find us at <a href="https://www.tipesites.com">Instagram</a>
        </div>
      </footer>
    </>
  );
}

export default MainPage;
