import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./App.css";

import SchoolRegisterPage from "./components/SchoolRegisterPage.js";
import MainPage from "./components/MainPage.js";
import AboutPage from "./components/AboutPage.js";
import TutorialPage from "./components/TutorialPage.js";
import PricesTablePage from "./components/PricesTablePage.js";
import PricesPage from "./components/PricesPage.js";
import ContactPage from "./components/ContactPage.js";
import NavBar from "./components/NavBar.js";

function App() {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Anton&family=Belanosima:wght@700&family=Montserrat:wght@300&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <NavBar />
      <main>
        <Routes>
          <Route path="" element={<MainPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/tutorial" element={<TutorialPage />} />
          <Route path="/price" element={<PricesTablePage />} />
          <Route path="/contact" element={<ContactPage />} />

          <Route path="/school-register" element={<SchoolRegisterPage />} />
        </Routes>
      </main>
      {location.pathname.toLowerCase() === "" && (
        <footer>
          <div>
            Email: <a href="mailto:hq@tipesites.site">hq@tipesites.site</a>
          </div>

          <div>Location: South Africa, Johannesburg</div>

          <div>
            Find us at <a href="https://www.tipesites.com">Instagram</a>
          </div>
        </footer>
      )}
    </>
  );
}

export default App;
