import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import styles from "./ContactPage.module.css";

function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    option: "product_inquiry",
  });

  useEffect(() => {
    emailjs.init("3oK9lyb1VrYb3a75l");
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const emailData = {
      user_name: formData.name,
      user_email: formData.email,
      message: formData.message,
      option: formData.option,
    };

    console.log("Form submitted:", emailData);

    emailjs.send("service_rr9yagl", "template_k36fmwp", emailData).then(
      (result) => {
        console.log(result.text);
        console.log("Form sent");
      },
      (error) => {
        console.log(error.text);
        console.log("Form failed to send");
      }
    );

    setFormData({
      name: "",
      email: "",
      message: "",
      option: "product_inquiry",
    });
  };

  return (
    <div className={styles.contactBody}>
      <div className={styles.info}>
        <h2><span>Contact</span> Us</h2>
        <br />
        <p>Thank you for visiting our website! If you have any questions, inquiries,
            or feedback, please don't hesitate to reach out to us.
            We're here to assist you and look forward to connecting with you!</p>
      </div>
      <form className={styles.contactContainer} onSubmit={sendEmail}>
        <div className={styles.formContent}>
          <input
            type="text"
            className={styles.input}
            placeholder="Full Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <input
            type="email"
            className={styles.input}
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <div className={styles.options}>
            <label>
              <input
                type="radio"
                value="product_inquiry"
                checked={formData.option === "product_inquiry"}
                name="option"
                onChange={handleInputChange}
              />
              Product Enquiry
            </label>
            <label>
              <input
                type="radio"
                value="meeting_scheduling"
                checked={formData.option === "meeting_scheduling"}
                name="option"
                onChange={handleInputChange}
              />
              Meeting Scheduling
            </label>
          </div>
          <textarea
            className={`${styles.message} ${styles.input}`}
            placeholder=" Type your message here..."
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <input className={styles.contactBtn} type="submit" />
      </form>
    </div>
  );
}

export default ContactPage;
