import styles from "./Loading.module.css";

const Loading = () => (
  <div className={styles.loadingContainer}>
    <div className={styles.loading}>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loading;
